import "@assets/css/global.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import React, { useState, useMemo } from "react";
import { navigate } from "gatsby";
import { useIntl } from "gatsby-plugin-intl";
import { RootContext } from "./Root";
import { isSamePath } from "../util";
import ReactPlayer from "react-player";
import bgm from "@assets/musics/bgm.mp3";
import sitemap from "@models/sitemap.json";
import Navbar, { NavbarWelcome, NavbarIndex, NavbarDownload } from "@components/Navbar";
import Footer from "@components/Footer";
import SidePanel from "@components/SidePanel";
import Rain from "@components/Rain";
import useDefaultLanguage from "@hooks/useDefaultLanguage";
import useLocationNoPrefix from "@hooks/useLocationNoPrefix";
import useCheckRevisited from "@hooks/useCheckRevisited";
import EmailModal from "@components/EmailModal";
import WechatQRCode from "@components/WechatQRCode";
import Meta from "@components/Meta";

const Root = ({ children }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [wechatQRcodeOpen, setWechatQRcodeOpen] = useState(false);
  const [musicOn, setMusicOn] = useState(false);
  useDefaultLanguage();
  const { pathname } = useLocationNoPrefix();
  const { locale } = useIntl();
  const { navigateArgs } = useCheckRevisited();
  const handleClickHome = () => {
    navigate(...navigateArgs);
  };

  const isMutedPages = useMemo(
    () =>
      isSamePath(`/${locale}${sitemap.index.path}`, pathname) ||
      isSamePath(`/${locale}${sitemap.welcome.path}`, pathname),
    [locale, pathname]
  );
  const isPlaying = musicOn && !isMutedPages;

  return (
    <>
      <Meta noindex={isSamePath(`/${locale}${sitemap.welcome.path}`, pathname)} />
      <ReactPlayer
        css={{
          display: "none",
        }}
        playing={isPlaying}
        url={bgm}
        volume={1}
        loop={true}
        config={{
          file: {
            attributes: {
              autoPlay: false,
            },
            forceAudio: true,
          },
        }}
      />
      <RootContext.Provider value={{ setModalOpen, setWechatQRcodeOpen, setMusicOn, musicOn }}>
        <EmailModal open={modalOpen} setOpen={setModalOpen} />
        <WechatQRCode open={wechatQRcodeOpen} setOpen={setWechatQRcodeOpen} />
        {isSamePath(`/${locale}${sitemap.index.path}`, pathname) ? (
          <NavbarIndex handleClickHome={handleClickHome} setModalOpen={setModalOpen} />
        ) : isSamePath(`/${locale}${sitemap.download.path}`, pathname) ? (
          <NavbarDownload handleClickHome={handleClickHome} />
        ) : isSamePath(`/${locale}${sitemap.welcome.path}`, pathname) ? (
          <NavbarWelcome setModalOpen={setModalOpen} />
        ) : (
          <Navbar />
        )}
        <SidePanel />
        <div
          css={{
            width: "100%",
            minHeight: "100vh",
            overflow: "hidden",
          }}>
          {children}
          {!isMutedPages && <Rain raining={!isPlaying} />}
        </div>
        <Footer />
      </RootContext.Provider>
    </>
  );
};

export default Root;
