import React from "react";
import { Link } from "gatsby";
import { useIntl } from "gatsby-plugin-intl";
import sitemap from "@models/sitemap.json";
import { isMobile } from "react-device-detect";

const DownloadButtonMobile = () => {
  const intl = useIntl();
  const { formatMessage, locale } = intl;
  const btnClass = `mobile-download--btn ${locale}`;

  let downloadBtn = (
    <Link to={`/${locale}${sitemap.download.path}`} className={btnClass}>
      {formatMessage({ id: "nav-download" })}
    </Link>
  );

  if (isMobile && locale === "zh-Hans") {
    downloadBtn = (
      <a
        className={btnClass}
        href={process.env.REACT_APP_Taptap_URL}
        target="_blank"
        rel="noopener noreferrer">
        {formatMessage({ id: "nav-download" })}
      </a>
    );
  } else if (isMobile) {
    downloadBtn = (
      <a
        className={btnClass}
        href={process.env.REACT_APP_APP_ADJUST_URL}
        target="_blank"
        rel="noopener noreferrer">
        {formatMessage({ id: "nav-download" })}
      </a>
    );
  }

  return downloadBtn;
};

export default DownloadButtonMobile;
