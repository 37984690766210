import facepaint from "facepaint";

export const breakpoints = {
  tablet: 768,
  desktop: 1200,
};

export const mq = facepaint(
  Object.entries(breakpoints).map(([_name, breakpoint]) => `@media (min-width: ${breakpoint}px)`)
);

export const isSamePath = (path1, path2) => {
  return path1.replace(/\/$/, "") === path2.replace(/\/$/, "");
};

export const filterLocale = (list, locale) => {
  return list.filter(el => {
    const pathArr = el.fileAbsolutePath.split(".");
    return pathArr[pathArr.length - 2] === locale;
  });
};

export const scrollTo = target => {
  if (typeof window === "undefined") return;

  const top = target.current?.offsetTop;

  window.scrollTo({ top, behavior: "smooth" });
};

export const prefix = url => `${process.env.GATSBY_PREFIX}${url}`;
