import React, { useEffect, useState, useContext } from "react";
import { Link } from "gatsby";
import { useIntl, navigate, changeLocale } from "gatsby-plugin-intl";
import useLocationNoPrefix from "@hooks/useLocationNoPrefix";
import useMusicPlayer from "@hooks/useMusicPlayer";
import DownloadButtonMobile from "@components/DownloadButtonMobile";
import sitemap from "@models/sitemap.json";
import language from "@models/language.json";
import languageFull from "@models/language-full.json";
import SNS_BUTTONS from "@models/snsbuttons";
import logo from "@images/navbar/deemo2-logo.png";
import btnLang from "@images/navbar/btn-lang-bg.png";
import btnMusicOn from "@images/navbar/btn-music-on.png";
import btnMusicOff from "@images/navbar/btn-music-off.png";
import btnShare from "@images/navbar/btn-share.png";
import homeIcon from "@images/navbar/home-icon.png";
import playerTipBg from "@images/navbar/player-tip-bg.png";
import btnTriangle from "@images/global/btn-triangle.png";
import btnX from "@images/global/btn-x.png";
import mobileMenu from "@images/global/mobile-menu.png";
import { RootContext } from "@layouts/Root";
import "./style.scss";

const NAVBAR_CONTENT = ["news", "how-to-play", "story", "download", "gallery"];
const MOBILE_NAVBAR_CONTENT = NAVBAR_CONTENT.filter(key => key !== "download");

const Navbar = () => {
  const { formatMessage, locale } = useIntl();
  const goToPage = key => {
    navigate(sitemap[key].path);
  };

  return (
    <div className="navbar">
      <div className="navbar--left">
        <MusicPlayerButton />
        <Link className="navbar--logo" to={`/${locale}${sitemap.home.path}`}>
          <img src={logo} alt="logo" />
        </Link>
      </div>
      <div className={`navbar--center ${locale}`}>
        {NAVBAR_CONTENT.map((key, idx) => (
          <React.Fragment key={idx}>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                goToPage(key);
              }}>
              {formatMessage({ id: `nav-${key}` })}
            </div>
            <div>|</div>
          </React.Fragment>
        ))}
        <a href={formatMessage({ id: "link-faq" })}>{formatMessage({ id: "nav-faq" })}</a>
      </div>
      <div className="navbar--right">
        <DownloadButtonMobile />
        <span></span>
        <MobileMenuButton />
        <LangSwitcher />
        <ShareButton />
      </div>
    </div>
  );
};

export default Navbar;

export const NavbarWelcome = () => {
  // PC only
  return (
    <div className="navbar">
      <div className="navbar--left"></div>
      <div className="navbar--center">
        <img src={logo} alt="logo" className="navbar--logo" />
      </div>
      <div className="navbar--right">
        <LangSwitcher />
        <ShareButton />
      </div>
    </div>
  );
};

export const NavbarIndex = ({ handleClickHome }) => {
  return (
    <div className="navbar">
      <div className="navbar--left">
        <img src={logo} alt="logo" className="mobile--logo" />
      </div>
      <div className="navbar--center">
        <img src={logo} alt="logo" className="navbar--logo" />
      </div>
      <div className="navbar--right">
        {/* mobile */}
        <DownloadButtonMobile />
        <span></span>
        <MobileHomeButton onClick={handleClickHome} />
        {/* pc */}
        <DownloadButton />
        <HomeButton onClick={handleClickHome} />
        <LangSwitcher />
        <ShareButton />
      </div>
    </div>
  );
};

export const NavbarDownload = ({ handleClickHome }) => {
  const { locale } = useIntl();

  return (
    <div className="navbar">
      <div className="navbar--left">
        <MusicPlayerButton />
      </div>
      <div className="navbar--center">
        <Link className="navbar--logo" to={`/${locale}${sitemap.home.path}`}>
          <img src={logo} alt="logo" />
        </Link>
      </div>
      <div className="navbar--right">
        {/* mobile */}
        <MobileHomeButton onClick={handleClickHome} />
        {/* pc */}
        <HomeButton onClick={handleClickHome} />
        <LangSwitcher />
        <ShareButton />
      </div>
    </div>
  );
};

const DownloadButton = () => {
  const { locale, formatMessage } = useIntl();
  return (
    <Link to={`/${locale}${sitemap.download.path}`}>
      <div className="navbar--downloadBtn"> {formatMessage({ id: "nav-download" })}</div>
    </Link>
  );
};

const HomeButton = ({ onClick }) => {
  const { formatMessage } = useIntl();

  return (
    <div onClick={onClick} className="navbar--homeBtn">
      {formatMessage({ id: "nav-home" })}
    </div>
  );
};

const ShareButton = () => {
  const [open, setOpen] = useState(false);
  const { setWechatQRcodeOpen } = useContext(RootContext);

  return (
    <div className="navbar--shareBtn">
      <img
        src={btnShare}
        alt="share-button"
        onClick={() => {
          setOpen(prev => !prev);
        }}
      />
      {open && (
        <div className="navbar--shareBtn--panel">
          {SNS_BUTTONS.map((el, idx) => {
            if (el.name === "WeChat")
              return (
                <button key={el.name}>
                  <img
                    key={idx}
                    src={el.navbar}
                    alt={el.name}
                    onClick={() => {
                      setWechatQRcodeOpen(true);
                    }}
                  />
                </button>
              );
            return (
              <a key={el.name} href={el.url} target="_blank" rel="noopener noreferrer">
                <img src={el.navbar} alt="sns-button" />
              </a>
            );
          })}
        </div>
      )}
    </div>
  );
};

const MusicPlayerButton = () => {
  const { locale } = useIntl();
  const { musicOn, setMusicOn } = useMusicPlayer();
  const [showMusicTips, setShowMusicTips] = useState(false);
  const { formatMessage } = useIntl();

  useEffect(() => {
    if (musicOn) return;

    let pathname = "";
    if (typeof window !== "undefined") pathname = window.location.pathname;
    const notShownPages = [`/${locale}${sitemap.welcome.path}`, `/${locale}${sitemap.index.path}`];
    if (!musicOn && !notShownPages.includes(pathname)) {
      setShowMusicTips(true);
    }
  }, [musicOn, locale]);

  return (
    <>
      <div className="navbar--musicBtn">
        <img
          src={musicOn ? btnMusicOn : btnMusicOff}
          onClick={() => {
            setMusicOn(prev => !prev);
            setShowMusicTips(false);
          }}
          alt="music-control-icon"
        />
        {showMusicTips && (
          <div
            className={`navbar--musicBtn--tip ${locale}`}
            style={{ backgroundImage: `url(${playerTipBg})` }}>
            <div style={{ paddingTop: "10px" }}>{formatMessage({ id: "nav-music-tip" })}</div>
          </div>
        )}
      </div>
    </>
  );
};

const LangSwitcher = () => {
  const [open, setOpen] = useState(false);
  const { locale } = useIntl();
  const location = useLocationNoPrefix();

  const onLanguageChange = locale => {
    setOpen(false);
    changeLocale(locale);
  };

  return (
    <div className="navbar--langSwitcher">
      <div
        className="navbar--langSwitcher--text"
        onClick={() => {
          setOpen(prev => !prev);
        }}>
        {language[locale]}
      </div>
      <img
        src={btnLang}
        className="navbar--langSwitcher--btn"
        onClick={() => {
          setOpen(prev => !prev);
        }}
        alt="langSwitcher"
      />
      {open && (
        <div className="navbar--langSwitcher--panel">
          {Object.keys(language).map((key, idx) => (
            <a
              key={idx}
              href={location.pathname.replace(`/${locale}/`, `/${key}/`)}
              onClick={e => {
                e.preventDefault();
                onLanguageChange(key);
              }}>
              <div>{language[key]}</div>
            </a>
          ))}
        </div>
      )}
    </div>
  );
};

const MobileHomeButton = ({ onClick }) => {
  const [open, setOpen] = useState(false);
  const location = useLocationNoPrefix();
  const { formatMessage, locale } = useIntl();
  return (
    <>
      <div
        className="mobile-home--btn"
        onClick={() => {
          setOpen(prev => !prev);
        }}>
        <img src={homeIcon} alt="home-icon" />
      </div>
      {open && (
        <div className="menu-modal">
          <img
            className="menu-modal--btnX"
            src={btnX}
            alt="close"
            onClick={() => {
              setOpen(false);
            }}
          />
          <div className="menu-modal--content">
            <div
              onClick={onClick}
              style={{ position: "relative", display: "flex", alignItems: "center" }}>
              {formatMessage({ id: "nav-home" })}
              <img
                src={btnTriangle}
                alt="icon-triangle"
                style={{
                  width: "12px",
                  marginLeft: "10px",
                }}
              />
            </div>
            {Object.keys(languageFull).map((key, index) => (
              <Link
                key={index}
                to={location.pathname.replace(`/${locale}/`, `/${key}/`)}
                style={{
                  display: "flex",
                  justifyContent: key === "en" ? "center" : "space-between",
                }}>
                {key === "en" ? (
                  <span className="menu-modal--normalLink">{languageFull[key]}</span>
                ) : (
                  languageFull[key].split("").map((char, idx) => <span key={idx}>{char}</span>)
                )}
              </Link>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

const MobileMenuButton = () => {
  const { setWechatQRcodeOpen } = useContext(RootContext);
  const [open, setOpen] = useState(false);
  const [langOpen, setLangOpen] = useState(false);
  const { formatMessage, locale } = useIntl();
  const location = useLocationNoPrefix();

  const goToPage = key => {
    setOpen(false);
    setLangOpen(false);
    navigate(sitemap[key].path);
  };

  const goToLocale = key => {
    setOpen(false);
    setLangOpen(false);
    changeLocale(key);
  };

  return (
    <>
      <div
        className="mobile-menu-btn"
        onClick={() => {
          setOpen(prev => !prev);
        }}>
        <img src={mobileMenu} alt="icon-menu" />
      </div>
      {open && (
        <div className="menu-modal">
          <img
            className="menu-modal--btnX"
            src={btnX}
            alt="close"
            onClick={() => {
              setOpen(false);
            }}
          />
          <div className="menu-modal--content">
            {MOBILE_NAVBAR_CONTENT.map((key, idx) => (
              <div
                key={idx}
                style={{ cursor: "pointer", height: "40px" }}
                onClick={() => {
                  goToPage(key);
                }}>
                {formatMessage({ id: `nav-${key}` })}
              </div>
            ))}
            <a
              style={{ display: "block", textAlign: "center", height: "40px" }}
              href={formatMessage({ id: "link-faq" })}>
              {formatMessage({ id: "nav-faq" })}
            </a>
            <div className="menu-modal--language">
              <div
                onClick={() => {
                  setLangOpen(prev => !prev);
                }}
                style={{
                  cursor: "pointer",
                  color: "#fff",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}>
                {formatMessage({ id: "language" })}
                <img
                  src={btnTriangle}
                  alt="icon-triangle"
                  style={{
                    width: "10px",
                    margin: "3px 0 0 10px",
                    transform: langOpen ? "rotate(0deg)" : "rotate(90deg)",
                    transition: "0.2s",
                  }}
                />
              </div>
              {langOpen &&
                Object.keys(languageFull).map((key, idx) => (
                  <div key={idx}>
                    <a
                      onClick={e => {
                        e.preventDefault();
                        goToLocale(key);
                      }}
                      href={location.pathname.replace(`/${locale}/`, `/${key}/`)}
                      style={{ display: "flex", justifyContent: "space-around" }}>
                      {languageFull[key].split("").map((char, char_idx) => (
                        <span key={char_idx}>{char}</span>
                      ))}
                    </a>
                  </div>
                ))}
            </div>
            <div className="menu-modal--share-btns">
              <div className="row first">
                {SNS_BUTTONS.slice(0, 4).map(el => (
                  <a key={el.name} href={el.url} target="_blank" rel="noopener noreferrer">
                    <img src={el.navbar} className="icon" alt={el.name} />
                  </a>
                ))}
              </div>
              <div className="row second">
                {SNS_BUTTONS.slice(4, 7).map(el => {
                  if (el.name === "WeChat") {
                    return (
                      <button key={el.name}>
                        <img
                          src={el.navbar}
                          className="icon"
                          alt={el.name}
                          onClick={() => {
                            setWechatQRcodeOpen(true);
                          }}
                        />
                      </button>
                    );
                  }

                  return (
                    <a key={el.name} href={el.url} target="_blank" rel="noopener noreferrer">
                      <img src={el.navbar} className="icon" alt={el.name} />
                    </a>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
