import React from "react";
import { Helmet } from "react-helmet";
import { useIntl } from "gatsby-plugin-intl";
import { OG_IMG_LINK } from "@models";

const canonical = "https://deemo.com/deemo2/";

const Meta = ({ noindex }) => {
  const { formatMessage } = useIntl();

  return (
    <Helmet title={formatMessage({ id: "meta-title" })}>
      {/* <!-- Mobile Specific Metas --> */}
      <meta name="format-detection" content="telephone=no" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
      />
      {/* no index nofollow */}
      {noindex && <meta name="robots" content="none" />}
      {/* Font */}
      <link
        href="https://fonts.googleapis.com/css?family=Noto+Sans:400,700|Noto+Sans+JP:400,700|Noto+Sans+KR:400,700|Noto+Sans+TC:400,700&display=swap"
        rel="stylesheet"
      />
      {/* <!-- Canonical SEO --> */}
      <link rel="canonical" href={canonical} />

      {/* <!-- Social tags --> */}
      <meta name="keywords" content={formatMessage({ id: "meta-keywords" })} />
      <meta name="description" content={formatMessage({ id: "meta-description" })} />

      {/* <!-- Favicon --> */}
      <link rel="shortcut icon" href={`${process.env.REACT_APP_SITE_URL}/favicon.ico`} />

      {/* <!-- Twitter Card data --> */}
      <meta name="twitter:title" content={formatMessage({ id: "meta-title" })} />
      <meta name="twitter:description" content={formatMessage({ id: "meta-description" })} />
      <meta name="twitter:image" content={OG_IMG_LINK} />
      {/* <meta name="twitter:card" content="summary_large_image"> */}
      <meta name="twitter:site" content="@DeemoRayark" />
      <meta name="twitter:creator" content="@RayarkOfficial" />

      {/* <!-- Open Graph data --> */}
      <meta property="og:title" content={formatMessage({ id: "meta-title" })} />
      <meta property="og:type" content="game" />
      <meta property="og:url" content={process.env.REACT_APP_SITE_URL} />
      <meta property="og:description" content={formatMessage({ id: "meta-description" })} />
      <meta property="og:site_name" content={formatMessage({ id: "meta-site_name" })} />
      <meta property="og:image" content={OG_IMG_LINK} />

      {/* egg */}
      <script type="text">{`/*
        ####################################################################################################
        ####################################################################################################
        ######################################################.#############################################
        ###################################################...##############################################
        ################################################.###..##############################################
        #############################################...####..##############################################
        ##########..##################################..####..##################..#.########################
        ###########.#.################################..####..#####/#.###########/.#########################
        ####.#.###..#################../##############..####..####.#############...:.#######################
        ######......##...############/.###############..####..####....##########....########################
        #####.#....####...##########..#.##############..####..#####....#########.....#.#############.##.####
        ########...#####..:###########/###############..####./#####....########....###/############.########
        ########...######..#########........##########..####./#####.../.#######.:..##############......#####
        ########:..######....########;.###############..####./#####...#.######.//..############./###..######
        ########...######....########..###############..####./####... #.######.##..###########,.#####..#####
        ########...######,..#########..###############..####./####....##.#####.##..###########.######.,#####
        ########...######..;#########..############.......##./#####...##.####.###..###########.######..#####
        ########:./######..##########...../#########.#//####./#####../##..###.###..##########/,######,.#####
        ########..#####....##########..#############.#//####./#####/.####.###.###..###########./#####..#####
        ########..#####....##########.,#############.#...###./######.####.##.####.,###########..#####.######
        ######...........##..#######.....:.,########.#//####./####.#.#####...####..############..###.#######
        ############################################.#//####./###....:.###..###......#######################
        ############################################.#//####./############..################################
        ############################################.......#./##############################################
        ################################################.###./##############################################
        ##############################################..####./##############################################
        ##############################################..####...#############################################
        ##############################################..###.################################################
        ##############################################...##################.################################
        ########################################..###.###########..#########################################
        ###################################.################################################################
        ####################.###############.##########################.##############.#####################
        ######################.###################.....#######...####################.######################
        ###########################..##########################################..###########################
        ########################################..################......####################################
        ####################################################################################################
        ####################################################################################################
        ####################################################################################################
        ############################## https://rayark.promo/DEEMOII_egg.mp4 ################################
        ####################################################################################################
      */`}</script>

      <script
        defer
        src="https://unpkg.com/smoothscroll-polyfill@0.4.4/dist/smoothscroll.min.js"></script>

      {/* Gtag Manager */}
      <script type="text/javascript">{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-NLB6VT');`}</script>
    </Helmet>
  );
};

export default Meta;
