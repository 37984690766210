import React from "react";
import weChatQRcodeImg from "@images/global/wechat-qrcode.jpg";

const WechatQRCode = ({ open, setOpen }) => {
  if (!open) return <></>;
  return (
    <div
      onClick={() => {
        setOpen(false);
      }}
      style={{
        position: "fixed",
        zIndex: 10000,
        width: "100vw",
        height: "100vh",
        backgroundColor: "rgba(0,0,0,0.9)",
        top: 0,
        left: 0,
      }}>
      <img
        src={weChatQRcodeImg}
        alt="wechat-icon"
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
        }}
      />
    </div>
  );
};

export default WechatQRCode;
