import { useIntl } from "gatsby-plugin-intl";
import sitemap from "@models/sitemap.json";
import { isMobile } from "react-device-detect";
import language from "@models/language.json";
import useLocationNoPrefix from "@hooks/useLocationNoPrefix";

function noLangPrefix(path) {
  return !Object.keys(language).includes(path.split("/")[1]);
}

const EIGHT_HOURS_MILLISECONDS = 8 * 3600 * 1000;
const checkRevisited = () => {
  //若8小時以內再訪，路徑活動頁→官網，不會再看到互動頁，但每日00：00重置。
  const lastVisited = typeof window !== "undefined" && localStorage.getItem("lastVisited");
  if (!lastVisited) return false;
  if (new Date().valueOf() - parseInt(lastVisited) > EIGHT_HOURS_MILLISECONDS) return false; //8小時以內再訪

  const lasVisitedDate = new Date(parseInt(lastVisited));
  if (
    new Date().getDate() !== lasVisitedDate.getDate() ||
    new Date().getMonth() !== lasVisitedDate.getMonth() ||
    new Date().getFullYear() !== lasVisitedDate.getFullYear()
  ) {
    return false; //不同日
  }

  return true; //不會再看到互動頁
};

const useCheckRevisited = () => {
  const location = useLocationNoPrefix();
  const { locale } = useIntl();

  // useDefaultLanguage in Root component will do redirection with language prefix first
  if (noLangPrefix(location.pathname)) return { navigateArgs: [] };

  // at welcome page, update
  if (location.pathname === `/${locale}${sitemap.welcome.path}`) {
    localStorage.setItem("lastVisited", new Date().valueOf());
    return { navigateArgs: [] };
  }

  // isMobile
  if (isMobile) return { navigateArgs: [`/${locale}${sitemap.home.path}`, { replace: true }] };

  // PC
  if (checkRevisited() === true) {
    return { navigateArgs: [`/${locale}${sitemap.home.path}`, { replace: true }] };
  } else {
    return { navigateArgs: [`/${locale}${sitemap.welcome.path}`, { replace: true }] };
  }
};

export default useCheckRevisited;
