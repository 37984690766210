import React, { useRef, useEffect, useState } from "react";
import modalClose from "@images/global/modal-close.png";
import emailIcon from "@images/global/modal-email-icon.png";
import { useIntl } from "gatsby-plugin-intl";
import "./style.scss";
import axios from "axios";

const submitBtnStyle = {
  width: "fit-content",
  border: "4px solid white",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  fontSize: "18px",
  padding: "8px 20px",
  margin: "60px auto 20px",
};

const modalContentStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)",
  width: "90vw",
  maxWidth: "686px",
  height: "80vh",
  maxHeight: "450px",
  backgroundColor: "#1f2025",
  color: "#ffffff",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
};

const modalContentInnerStyle = {
  width: "100%",
  height: "100%",
  textAlign: "center",
};

const emailFieldStyle = {
  display: "grid",
  gridTemplateColumns: "1fr 9fr",
  columnGap: "20px",
  maxWidth: "360px",
  height: "44px",
  alignItems: "center",
  margin: "40px auto",
};

const EmailModal = ({ open, setOpen }) => {
  const { formatMessage } = useIntl();
  const inputRef = useRef(null);
  const [successMsg, setSuccessMsg] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [btnDisabled, setBtnDisabled] = useState(false);

  useEffect(() => {
    if (open) {
      window.document.body.style.height = "100%";
      window.document.body.style.overflow = "hidden";
    }
    return () => {
      window.document.body.style.height = "initial";
      window.document.body.style.overflow = "auto";
    };
  }, [open]);

  if (!open) return <></>;

  const handleSubmit = () => {
    if (btnDisabled) return;
    setErrMsg("");
    setBtnDisabled(true);
    setTimeout(() => {
      setBtnDisabled(false);
    }, 2000);

    let formData = new FormData();
    formData.append("email", inputRef.current.value);
    formData.append("token", formatMessage({ id: "widgetToken" }));

    axios
      .post(process.env.REACT_APP_SG_WIDGET_URL, formData)
      .then(response => {
        setSuccessMsg(response.data?.message);
        setErrMsg("");
      })
      .catch(() => {
        setErrMsg(formatMessage({ id: "email-error-msg" }));
      });
  };

  return (
    <div
      className="email-modal"
      style={{
        position: "fixed",
        zIndex: 10000,
        width: "100vw",
        height: "100vh",
        backgroundColor: "rgba(0,0,0,0.9)",
      }}>
      <div style={modalContentStyle}>
        <img
          src={modalClose}
          alt="close-button"
          style={{
            position: "absolute",
            width: "30px",
            right: 0,
            top: "-30px",
            cursor: "pointer",
          }}
          onClick={() => {
            setOpen(false);
          }}
        />
        {successMsg ? (
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%,-50%)",
            }}>
            {successMsg}
          </div>
        ) : (
          <section className="email-content" style={modalContentInnerStyle}>
            <p className="email-title">{formatMessage({ id: "email-ios-register" })}</p>
            <p className="email-text">{formatMessage({ id: "email-ios-text1" })}</p>
            <p className="email-text">{formatMessage({ id: "email-ios-text2" })}</p>
            <div style={emailFieldStyle}>
              <img src={emailIcon} alt="email-icon" style={{ width: "40px" }} />
              <input
                ref={inputRef}
                className="sg_email"
                style={{ borderRadius: "7px", height: "100%", padding: "5px 20px" }}
                type="email"
                name="sg_email"
                placeholder=""
                required="required"
              />
            </div>
            <div style={{ color: "#e9acaf", fontWeight: 500 }}> {errMsg}</div>
            <div
              type="submit"
              className="sg-submit-btn"
              value=""
              id={formatMessage({ id: "widgetClass" })}
              style={submitBtnStyle}
              onClick={handleSubmit}>
              Send
            </div>
          </section>
        )}
      </div>
    </div>
  );
};

export default EmailModal;
