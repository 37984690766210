import React from "react";
import { keyframes } from "@emotion/react";
import round from "lodash.round";
import { mq } from "../util";

const RAIN_DROPS_COUNT = 850;

function generateRange(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

const rainDrops = Array.from({ length: RAIN_DROPS_COUNT }, (_v, k) => k).map(index => ({
  left: generateRange(0, 2880),
  top: generateRange(-1000, 1400),
  background: `linear-gradient(to bottom, rgba(255,255,255, 0), rgba(255,255,255, ${
    round((index % 6) * 0.1, 1) || 0.1
  }))`,
  transitionDelay: `${(index % 3) * 0.3}s`,
}));

const fall = keyframes`
  to {
    transform: translateY(900px) translateX(50px);
  }
`;

const Rain = ({ raining = false }) => (
  <div
    css={{
      position: "fixed",
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      pointerEvents: "none",
    }}>
    {rainDrops.map((styles, i) => (
      <div
        key={`drops-${i}`}
        css={mq({
          ...styles,
          opacity: [0.125, null, raining ? 1 : 0.125],
          width: 1,
          height: 50,
          position: "absolute",
          bottom: 200,
          willChange: "tranform, opacity",
          animation: `${fall} 0.63s linear infinite`,
          transitionProperty: "opacity",
          transitionDuration: "3s",
        })}
      />
    ))}
  </div>
);

export default Rain;
