import { useContext } from "react";
import { RootContext } from "@layouts/Root";

const useMusicPlayer = () => {
  const { setMusicOn, musicOn } = useContext(RootContext);

  return { setMusicOn, musicOn };
};

export default useMusicPlayer;
