import React, { useState, useContext } from "react";
import "./style.scss";
import panelArrow from "@images/global/panel-arrow.png";
import SNS_BUTTONS from "@models/snsbuttons";
import { RootContext } from "@layouts/Root";

const SidePanel = () => {
  const [open, setOpen] = useState(false);
  const { setWechatQRcodeOpen } = useContext(RootContext);

  return (
    <div className={`sidePanel ${open ? "open" : ""}`}>
      <div
        className="sidePanel--arrow"
        onClick={() => {
          setOpen(prev => !prev);
        }}>
        <img src={panelArrow} alt="arrow-icon" />
      </div>
      <div className="sidePanel--panel">
        {SNS_BUTTONS.map((el, idx) => {
          if (el.name === "WeChat")
            return (
              <button key={idx} className="row" style={{ cursor: "pointer" }}>
                <img
                  src={el.sidePanel}
                  alt={el.name}
                  onClick={() => {
                    setWechatQRcodeOpen(true);
                  }}
                />
                {el.name}
              </button>
            );

          return (
            <a key={idx} className="row" href={el.url} target="_blank" rel="noopener noreferrer">
              <img src={el.sidePanel} alt={el.name} />
              {el.name}
            </a>
          );
        })}
      </div>
    </div>
  );
};

export default SidePanel;
