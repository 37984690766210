import React, { useContext } from "react";
import "./style.scss";
import SNS_BUTTONS from "@models/snsbuttons";
import rayarkLogo from "@images/footer/rayark-logo.png";
import jetGenLogo from "@images/footer/jetgen-logo.png";
import { useIntl } from "gatsby-plugin-intl";
import { RootContext } from "@layouts/Root";
const Footer = () => {
  const { formatMessage } = useIntl();
  const { setWechatQRcodeOpen } = useContext(RootContext);
  return (
    <div className="footer">
      <div
        className="text-center"
        style={{ fontSize: "24px", marginBottom: "20px", letterSpacing: "1px" }}>
        - Follow US -
      </div>
      <div className="social-btns">
        <div className="row">
          {SNS_BUTTONS.slice(0, 4).map((el, idx) => (
            <a key={idx} href={el.url} target="_blank" rel="noopener noreferrer">
              <img src={el.footer} alt={el.name} />
            </a>
          ))}
        </div>
        <div className="row">
          {SNS_BUTTONS.slice(4, 7).map((el, idx) => {
            if (el.name === "WeChat")
              return (
                <button style={{ cursor: "pointer" }} key={idx}>
                  <img
                    src={el.footer}
                    alt={el.name}
                    onClick={() => {
                      setWechatQRcodeOpen(true);
                    }}
                  />
                </button>
              );
            return (
              <a key={idx} href={el?.url || "/"} target="_blank" rel="noopener noreferrer">
                <img src={el.footer} alt={el.name} />
              </a>
            );
          })}
        </div>
      </div>
      <div className="bottom">
        <div className="row">
          <div className="wrapper">
            <img src={jetGenLogo} className="rayark-logo" alt="logo" style={{ height: "100%" }} />
            <img src={rayarkLogo} className="rayark-logo" alt="logo" />
          </div>
        </div>
        <div className="row">
          <p>© {new Date().getFullYear()} Rayark Inc. All Rights Reserved. </p>
          <p></p>
        </div>

        <div className="row" style={{ marginTop: "10px" }}>
          <a href={process.env.REACT_APP_PRIVACY_URL} target="_blank" rel="noopener noreferrer">
            {formatMessage({ id: "footer-privacy" })}
          </a>
          <span style={{ margin: "0 8px" }}>|</span>
          <a
            href={process.env.REACT_APP_TERMS_OF_USE_URL}
            target="_blank"
            rel="noopener noreferrer">
            {formatMessage({ id: "footer-terms-of-Service" })}
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
