import { useEffect } from "react";
import useLocationNoPrefix from "@hooks/useLocationNoPrefix";
import language from "@models/language.json";
import { navigate } from "gatsby";
import { useIntl } from "gatsby-plugin-intl";

function noLangPrefix(path) {
  return !Object.keys(language).includes(path.split("/")[1]);
}

const getIntlLangKey = userAgentLanguage => {
  if (
    Object.keys(language)
      .map(key => key.split("-")[0])
      .includes(userAgentLanguage.split("-")[0])
  ) {
    if (userAgentLanguage.split("-")[0] === "zh") {
      switch (userAgentLanguage) {
        case "zh-CN":
          return "zh-Hans";
        default:
          return "zh-Hant";
      }
    } else return userAgentLanguage.split("-")[0];
  } else return "en";
};

const useDefaultLanguage = () => {
  const location = useLocationNoPrefix();
  const { locale } = useIntl();
  //in local: /
  //in preview Page: deemo-official/deemo2/
  //in production: deemo2/
  useEffect(() => {
    if (noLangPrefix(location.pathname)) {
      //redirect
      const userAgentLanguage = window.navigator.language;
      const localStorageLang = localStorage.getItem("lang");
      const intlLangKey = localStorageLang || getIntlLangKey(userAgentLanguage);
      navigate(`/${intlLangKey}${location.pathname}`);
    } else {
      //update localStorage
      localStorage.setItem("lang", locale);
    }
  }, [locale, location]);
};

export default useDefaultLanguage;
