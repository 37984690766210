import btnShare01 from "@images/navbar/navbar-btn-share-01.png";
import btnShare02 from "@images/navbar/navbar-btn-share-02.png";
import btnShare03 from "@images/navbar/navbar-btn-share-03.png";
import btnShare04 from "@images/navbar/navbar-btn-share-04.png";
import btnShare05 from "@images/navbar/navbar-btn-share-05.png";
import btnShare06 from "@images/navbar/navbar-btn-share-06.png";
import btnShare07 from "@images/navbar/navbar-btn-share-07.png";

import sidePanelSnk01 from "@images/sidepanel/side-panel-snk-01.png";
import sidePanelSnk02 from "@images/sidepanel/side-panel-snk-02.png";
import sidePanelSnk03 from "@images/sidepanel/side-panel-snk-03.png";
import sidePanelSnk04 from "@images/sidepanel/side-panel-snk-04.png";
import sidePanelSnk05 from "@images/sidepanel/side-panel-snk-05.png";
import sidePanelSnk06 from "@images/sidepanel/side-panel-snk-06.png";
import sidePanelSnk07 from "@images/sidepanel/side-panel-snk-07.png";

import btnSocial1 from "@images/footer/btn-social-01.png";
import btnSocial2 from "@images/footer/btn-social-02.png";
import btnSocial3 from "@images/footer/btn-social-03.png";
import btnSocial4 from "@images/footer/btn-social-04.png";
import btnSocial5 from "@images/footer/btn-social-05.png";
import btnSocial6 from "@images/footer/btn-social-06.png";
import btnSocial7 from "@images/footer/btn-social-07.png";

const SNS_BUTTONS = [
  {
    name: "Facebook",
    navbar: btnShare01,
    sidePanel: sidePanelSnk01,
    footer: btnSocial1,
    url: process.env.REACT_APP_Facebook_URL,
  },
  {
    name: "Instagram",
    navbar: btnShare02,
    sidePanel: sidePanelSnk02,
    footer: btnSocial2,
    url: process.env.REACT_APP_Instagram_URL,
  },
  {
    name: "Twitter",
    navbar: btnShare03,
    sidePanel: sidePanelSnk03,
    footer: btnSocial3,
    url: process.env.REACT_APP_Twitter_URL,
  },
  {
    name: "Youtube",
    navbar: btnShare04,
    sidePanel: sidePanelSnk04,
    footer: btnSocial4,
    url: process.env.REACT_APP_Youtube_URL,
  },
  {
    name: "Weibo",
    navbar: btnShare05,
    sidePanel: sidePanelSnk05,
    footer: btnSocial5,
    url: process.env.REACT_APP_Weibo_URL,
  },
  {
    name: "WeChat",
    navbar: btnShare06,
    sidePanel: sidePanelSnk06,
    footer: btnSocial6,
  },
  {
    name: "Bilibili",
    navbar: btnShare07,
    sidePanel: sidePanelSnk07,
    footer: btnSocial7,
    url: process.env.REACT_APP_Bilibili_URL,
  },
];

export default SNS_BUTTONS;
