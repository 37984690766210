import { useLocation } from "@reach/router";

//remove prefix in location.pathname
const useLocationNoPrefix = () => {
  const location = useLocation();
  if (process.env.NODE_ENV === "development") return location;
  else return { ...location, pathname: location.pathname.replace(process.env.GATSBY_PREFIX, "") };
};

export default useLocationNoPrefix;
